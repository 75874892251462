@import '../../../App.scss';

.btn-red {
	display: inline-flex;
	align-items: center;
	text-align: center;
	padding: 0.5px 10px;
	border-radius: 15px;
	color: $red;
	font-size: 14px;
	font-weight: $semibold-weight;
	transition: 0.2s;
	// width: 94px;
	// border: 2px solid $red;
	// box-shadow: $box-shadow-sm;
	width: 40px;
		border: none;
		box-shadow: none;
	@include respond(phone) {
		width: 40px;
		border: none;
		box-shadow: none;
	}
	span {
		color: $red;

		// margin-left: -6px;
		// font-size: 20px;
		// font-weight: $semibold-weight;
		margin-left: 0;
			font-size: 26px;
			text-shadow: $txt-shadow;
			font-weight: $regular-weight;

		@include respond(phone) {
			margin-left: 0;
			font-size: 26px;
			text-shadow: $txt-shadow;
			font-weight: $regular-weight;
		}
	}
	&:hover {
		transform: translateY(2px);
	}
}
