@import '../../../App.scss';

.cart-dropdown-wrapper {
	height: 100vh;
	width: 450px;
	position: absolute;
	top: 0px;
	right: 0px;
	z-index: 5;
	transition: 0.2s;
	@include respond(phone) {
		width: 100%;
	}

	.loading-container {
		min-height: calc(100% - 60px);
		display: flex;
		align-items: center;
		justify-content: center;
		.big-loading-bars {
			width: 100%;
			height: 300px;
			// margin-top: -50px;
		}
	}

	.cart-dropdown-container {
		height: 100%;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		box-shadow: $box-shadow;
		background: $linear-gradient;
		transition: 0.2s;
		padding-bottom: 40px;
		@include respond(phone) {
			padding-bottom: 60px;
		}

		.cart-top {
			display: flex;
			justify-content: flex-start;
			padding: 0 20px;
			height: 60px;
			background: $blue-brand;
			@include respond(phone) {
				padding: 10px 20px;
			}
			.cart-title {
				display: flex;
				flex-basis: 80%;
				align-items: center;
				justify-content: flex-start;
				font-size: 24px;
				font-weight: $extrabold-weight;
				color: $white;
				&:before {
					content: ' ';
					display: flex;
					width: 7px;
					height: 24px;
					margin-right: 10px;
					background-color: $yellow-base;
				}
			}
			.cart-close {
				flex-basis: 20%;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				button {
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					span {
						width: 100%;
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: flex-end;
						font-size: 38px;
						font-weight: $extrabold-weight;
						color: $red;
						text-shadow: $txt-shadow;
						transition: 0.2s;
						&:hover {
							transform: translateY(-1.5px);
							text-shadow: $txt-shadow-lg;
						}
					}
				}
			}
		}

		.empty-message {
			font-size: 18px;
			margin: 50px auto;
		}

		.cart-content-container {
			min-height: calc(100% - 60px);
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		.cart-items-container {
			flex-basis: 80%;
			gap: 30px;
			padding: 15px;
			display: flex;
			flex-direction: column;
			overflow-y: visible;
			overflow-x: hidden;
			margin-right: 10px;
		}

		.cart-orçamento {
			flex-basis: 20%;
			align-items: flex-end;
			justify-content: flex-end;
			padding-bottom: 20px;
			.btns-container {
				margin: auto 20px;
				display: block;
				align-items: center;
				justify-content: space-between;
				@include respond(phone) {
					display: block;
					gap: 5px;
				}
			}
			.orçamento-total {
				margin: auto 20px;
				.orçamento-total-content {
					display: flex;
					justify-content: space-between;
					align-items: center;
					.orçamento-subtotal {
						font-size: 18px;
						font-weight: $extrabold-weight;
						color: $grey-dark;
						text-transform: uppercase;
					}
					.orçamento-número {
						display: flex;
						flex-direction: column;
						align-items: flex-end;
						justify-content: space-between;
						font-size: 32px;

						@include respond(phone) {
							font-size: 22px;
						}
						.price-green {
							font-size: 28px;
							line-height: 28px;
							color: $green-base;
							font-weight: $extrabold-weight;
						}
						.price-blue {
							font-weight: $bold-weight;
							color: $blue-brand;
							font-size: 18px;
							line-height: 18px;
						}

						h4 {
							font-size: 16px;
							line-height: 16px;
							color: $grey-base;
							font-weight: $regular-weight;
						}
						h3 {
							font-size: 14px;
							line-height: 14px;
							color: $grey-base;
							font-weight: $regular-weight;
						}
					}
				}
				&:after {
					margin: 20px 0;
					content: ' ';
					display: block;
					width: 100%;
					background-color: $grey-light;
					height: 2px;
				}
				&:before {
					margin: 20px 0;
					content: ' ';
					display: block;
					width: 100%;
					background-color: $grey-light;
					height: 2px;
				}
			}
		}
		animation: myAnim 150ms ease-in-out 0s 1 normal forwards;
		@keyframes myAnim {
			0% {
				opacity: 0;
				transform: translateX(250px);
			}

			100% {
				opacity: 1;
				transform: translateX(0);
			}
		}
	}
}
