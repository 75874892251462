@import '../../App.scss';

.footer-wrapper {
	position: relative;
	&:before {
		content: '';
		position: absolute;
		top: -350px;
		left: 0;
		width: 100%;
		min-height: 350px;
		background: url('../../assets/Bgs/bg-wave-4.svg');
		background-size: cover;
		background-repeat: no-repeat;
		z-index: -1000;
		@include respond(phone) {
			top: -250px;
			min-height: 250px;
		}
	}
}
