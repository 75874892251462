@import '../../../App.scss';

.button-sm {
	.btn-blue {
		display: inline-flex;
		align-items: center;
		text-align: center;
		padding: 20px 34px;
		border-radius: 40px;
		color: $white;
		background-color: $blue-brand;
		font-size: 20px;
		font-weight: $bold-weight;
		transition: 0.2s;
		box-shadow: $box-shadow-sm;
		span {
			color: $white;
			font-size: 28px;
			margin-left: 5px;
		}
		&:hover {
			background-color: $green-medium;
			transform: translateX(5px);
		}
	}

	.btn-yellow {
		display: inline-flex;
		align-items: center;
		text-align: center;
		padding: 10px 25px;
		border-radius: 40px;
		color: $white;
		background-color: $yellow-base;
		font-size: 16px;
		font-weight: $semibold-weight;
		transition: 0.2s;
		box-shadow: $box-shadow-sm;
		&:hover {
			background-color: $green-base;
			transform: translateX(5px);
		}
	}

	.btn-yellow-md {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 65px;
		width: 200px;
		border-radius: 40px;
		color: $white;
		background-color: $yellow-base;
		font-size: 20px;
		font-weight: $bold-weight;
		transition: 0.2s;
		box-shadow: $box-shadow-sm;
		@include respond(phone){
			width: 100%;
		}
		&:hover {
			background-color: $green-base;
			transform: translateY(-3px);
		}
	}

	.btn-green-md {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 60px;
		width: 200px;
		border-radius: 40px;
		color: $white;
		background-color: $green-base;
		font-size: 20px;
		font-weight: $semibold-weight;
		transition: 0.2s;
		box-shadow: $box-shadow-sm;
		@include respond(phone){
			width: 100%;
		}
		&:hover {
			background-color: $yellow-base;
			transform: translateY(-3px);
		}
	}

	.btn-grey-sm {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 50px;
		width: 160px;
		border: 4px solid $grey-dark;
		border-radius: 40px;
		color: $grey-dark;
		background-color: $white;
		font-size: 18px;
		font-weight: $bold-weight;
		transition: 0.2s;
		box-shadow: $box-shadow-sm;
		&:hover {
			transform: translateX(-3px);
		}
	}

	.btn-red-sm {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 50px;
		width: 160px;
		border: 4px solid $red;
		border-radius: 40px;
		color: $red;
		background-color: $white;
		font-size: 18px;
		font-weight: $bold-weight;
		transition: 0.2s;
		box-shadow: $box-shadow-sm;
		&:hover {
			transform: translateX(-3px);
		}
	}

	.btn-green-sm {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 50px;
		width: 160px;
		border-radius: 40px;
		color: $white;
		background-color: $green-medium;
		font-size: 18px;
		font-weight: $bold-weight;
		transition: 0.2s;
		box-shadow: $box-shadow-sm;
		&:hover {
			background-color: $yellow-base;
			transform: translateX(3px);
		}
	}

	.btn-yellow-sm {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 50px;
		width: 160px;
		border-radius: 40px;
		color: $white;
		background-color: $yellow-base;
		font-size: 18px;
		font-weight: $bold-weight;
		transition: 0.2s;
		box-shadow: $box-shadow-sm;
		&:hover {
			background-color: $green-medium;
			transform: translateX(3px);
		}
	}

	.btn-sm-disabled {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 50px;
		width: 160px;
		border-radius: 40px;
		color: $white;
		background-color: $grey-medium;
		font-size: 18px;
		font-weight: $bold-weight;
		transition: 0.2s;
		box-shadow: $box-shadow-sm;
		cursor: not-allowed;
	}

	.btn-lg-disabled {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 65px;
		width: 250px;
		border-radius: 40px;
		color: $white;
		background-color: $grey-medium;
		font-size: 20px;
		font-weight: $bold-weight;
		transition: 0.2s;
		box-shadow: $box-shadow-sm;
		cursor: not-allowed;
		@include respond(phone) {
			width: 100%;
			height: 60px;
			font-weight: $semibold-weight;
			box-shadow: $box-shadow;
		}
	}

	.btn-blue-sm {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 50px;
		width: 160px;
		border-radius: 40px;
		color: $white;
		background-color: $blue-brand;
		font-size: 18px;
		font-weight: $bold-weight;
		transition: 0.2s;
		box-shadow: $box-shadow-sm;
		&:hover {
			background-color: $yellow-base;
			transform: translateX(3px);
		}
	}

	.btn-yellow-lg {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 65px;
		width: 250px;
		border-radius: 40px;
		color: $white;
		background-color: $green-base;
		font-size: 20px;
		font-weight: $bold-weight;
		transition: 0.2s;
		box-shadow: $box-shadow-sm;
		@include respond(phone) {
			width: 100%;
			height: 60px;
			font-weight: $semibold-weight;
			box-shadow: $box-shadow;
		}
		&:hover {
			background-color: $yellow-base;
			transform: translateX(5px);
		}
	}

	.btn-nomatch {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 65px;
		width: 250px;
		border-radius: 40px;
		color: $white;
		background-color: $yellow-base;
		font-size: 20px;
		font-weight: $bold-weight;
		transition: 0.2s;
		box-shadow: $box-shadow-sm;
		@include respond(phone) {
			width: 100%;
			height: 60px;
			font-weight: $semibold-weight;
			box-shadow: $box-shadow;
		}
		&:hover {
			background-color: $blue-brand;
			transform: translateX(5px);
		}
	}

	.btn-cart {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 60px;
		border-radius: 40px;
		color: $white;
		background-color: $green-base;
		font-size: 20px;
		font-weight: $bold-weight;
		transition: 0.2s;
		box-shadow: $box-shadow-sm;
		@include respond(phone) {
			width: 100%;
			height: 60px;
			font-weight: $semibold-weight;
			box-shadow: $box-shadow;
		}
		&:hover {
			background-color: $yellow-base;
			transform: translateY(-3px);
		}
		span {
			margin-left: 5px;
			color: $white;
			font-size: 32px;
		}
	}

	.btn-disabled-lg {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 65px;
		width: 250px;
		border-radius: 40px;
		color: $white;
		background-color: $grey-medium;
		font-size: 20px;
		font-weight: $bold-weight;
		transition: 0.2s;
		box-shadow: $box-shadow-sm;
	}

	.btn-grey-lg {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 65px;
		width: 250px;
		border-radius: 40px;
		color: $grey-brand;
		border: 4px solid $grey-brand;
		background-color: $white;
		font-size: 18px;
		font-weight: $semibold-weight;
		transition: 0.2s;
		box-shadow: $box-shadow-sm;
		@include respond(phone) {
			width: 100%;
			height: 60px;
		}
		&:hover {
			transform: translateX(5px);
		}
	}

	.btn-grey-disabled-lg {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 65px;
		width: 250px;
		border-radius: 40px;
		color: $grey-brand;
		border: 4px solid $grey-brand;
		background-color: $white;
		font-size: 18px;
		font-weight: $semibold-weight;
		transition: 0.2s;
		box-shadow: $box-shadow-sm;
	}

	.btn-grey {
		display: inline-flex;
		align-items: center;
		text-align: center;
		padding: 6px 35px;
		border-radius: 40px;
		color: $grey-dark;
		border: 4px solid $grey-dark;
		background-color: transparent;
		font-size: 16px;
		font-weight: $semibold-weight;
		transition: 0.2s;
		box-shadow: $box-shadow-sm;
		&:hover {
			background-color: transparent;
			transform: translateX(5px);
		}
	}

	.btn-red {
		display: inline-flex;
		align-items: center;
		text-align: center;
		padding: 0.5px 10px;
		border-radius: 15px;
		color: $red;
		border: 2px solid $red;
		font-size: 14px;
		font-weight: $semibold-weight;
		transition: 0.2s;
		box-shadow: $box-shadow-sm;
		span {
			font-size: 20px;
			font-weight: $semibold-weight;
			color: $red;
			margin-left: -6px;
		}
		&:hover {
			transform: translateY(2px);
		}
	}

	.btn-comprovante {
		display: inline-flex;
		align-items: center;
		text-align: center;
		padding: 3px 13px;
		border-radius: 20px;
		color: $green-medium;
		border: 3px solid $green-medium;
		font-size: 16px;
		font-weight: $semibold-weight;
		transition: 0.2s;
		box-shadow: $box-shadow-sm;
		@include respond(phone) {
			font-size: 12px;
			padding: 2px 10px;
			font-weight: $regular-weight;
			border: 2px solid $green-medium;
		}
		span {
			font-size: 24px;
			color: $green-medium;
			margin-left: -6px;
			@include respond(phone) {
				font-size: 14px;
			}
		}
		&:hover {
			transform: translateY(2px);
		}
	}

	.btn-green {
		display: inline-flex;
		align-items: center;
		text-align: center;
		padding: 20px 34px;
		border-radius: 40px;
		color: $white;
		background-color: $green-medium;
		font-size: 20px;
		font-weight: $bold-weight;
		transition: 0.2s;
		box-shadow: $box-shadow-sm;
		span {
			color: $white;
			font-size: 28px;
			margin-left: 5px;
		}
		&:hover {
			background-color: $yellow-base;
			transform: translateX(5px);
		}
	}

	.btn-disabled {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 70px;
		width: 270px;
		border-radius: 40px;
		color: $white;
		background-color: $grey-base;
		font-size: 20px;
		font-weight: $bold-weight;
		transition: 0.2s;
		box-shadow: $box-shadow-sm;
		cursor: not-allowed;
		@include respond(phone) {
			width: 100%;
			font-weight: $semibold-weight;
			box-shadow: $box-shadow;
		}
	}

	.btn-enabled {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 70px;
		width: 270px;
		border-radius: 40px;
		color: $white;
		background-color: $green-base;
		font-size: 20px;
		font-weight: $bold-weight;
		transition: 0.2s;
		box-shadow: $box-shadow-sm;
		@include respond(phone) {
			width: 100%;
			font-weight: $semibold-weight;
			box-shadow: $box-shadow;
		}
		&:hover {
			background-color: $yellow-base;
			transform: translateX(5px);
		}
	}
}
