@import '../../../App.scss';

.footer-bottom-wrapper {
	background-color: $grey-light;
	min-height: 50px;
	width: 100%;
	overflow: hidden;
	@include respond(phone) {
		height: 165px;
	}
	.footer-bottom-container {
		display: flex;
		margin: auto 200px;
		padding: 15px 0;
		@include respond(tab-port) {
			display: block;
			margin: auto 10px;
			padding: 10px 0;
		}
		.footer-bottom-left {
			flex-basis: 50%;
			display: flex;
			flex-direction: column;
			text-align: left;
			@include respond(tab-port) {
				flex-basis: 100%;
				text-align: center;
			}
			.cnpj-top {
				font-weight: $bold-weight;
				font-size: 16px;
				color: $grey-dark;
				@include respond(tab-port) {
					font-size: 12px;
				}
			}

			.cnpj-bottom {
				font-weight: $semibold-weight;
				font-size: 12px;
				color: $grey-dark;
				@include respond(phone) {
					font-size: 10px;
				}
			}
		}
		.footer-bottom-right {
			flex-basis: 50%;
			gap: 20px;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			img{
				max-width: 50px;
			}

			@include respond(tab-port) {
				margin-top: 5px;
				flex-basis: 100%;
				gap: 10px;
				justify-content: center;
			}
		}
	}
}
